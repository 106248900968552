var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"100a4e314bf9bc0b0b401aedad644dd9ff74041f"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const TRACE_SAMPLES_RATE =
  process.env.SENTRY_TRACE_SAMPLES_RATE === 'true'
    ? true
    : false || process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLES_RATE === 'true'
    ? true
    : false;

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: TRACE_SAMPLES_RATE
});
